import React from 'react'
import { graphql, Link } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import { buildImageObj, cn, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageTransition from 'gatsby-plugin-page-transitions';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import MediaQuery from 'react-responsive'
import { AiOutlineClockCircle } from 'react-icons/ai'

import { responsiveTitle1, responsiveTitle3} from '../components/typography.module.css'

export const query = graphql`
query ClassPageQuery {
  classes: allSanityClasses(sort: {fields: publishedAt, order: DESC}) {
    edges {
      node {
        title
        url
        _id
        description
        length
        mainImage {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
}
`

const ClassPage = props => {
  const { data, errors } = props
  console.log(data.classes.edges)
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout>
      <PageTransition>
      <SEO title='Classes' />
      <Container className="class-page">
        <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className={responsiveTitle1}>On-Demand Classes</h1>
        <div className="class-wrapper">
        {data.classes.edges.map(({ node }) => (
          <OutboundLink className="class-box" target="_blank" href={node.url} key={node._id}>
            <div>
              <h3 className={responsiveTitle3}>
                {node.title}
              </h3>
              <span className="class-length"><AiOutlineClockCircle /> {node.length}</span>
              <p>{node.description}</p>
              <MediaQuery minWidth={675}>
                <span className="btn-link">Watch Now</span>
              </MediaQuery>
            </div>
            <div>
              {node.mainImage && node.mainImage.asset && (
                <img
                  src={imageUrlFor(buildImageObj(node.mainImage))
                    .width(600)
                    .height(Math.floor((9 / 16) * 600))
                    .url()}
                  // alt={node.mainImage.alt}
                />
              )}
              <MediaQuery maxWidth={674}>
                <span className="btn-link">Watch Now</span>
              </MediaQuery>
            </div>
          </OutboundLink>
      ))} 
      </div>
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default ClassPage
